<template>
        <v-card>
            <v-toolbar dark color="blue" class="darken-1">
                <v-toolbar-title>
                    Download Manual
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="dialogueClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-row dense>
                <v-col
                        :key="indexDownload"
                        cols="4"
                        v-for="(download,indexDownload) in downloadData"
                >
                    <v-card color="#1976D2" dark class="col-border pa-2 ma-3" @click="onDownload(download)">

                        <v-toolbar-title>

                            {{download.sector}}

                            <v-icon>mdi-cloud-download</v-icon>

                        </v-toolbar-title>

                    </v-card>
                </v-col>
            </v-row>
        </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "List",
         data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                downloadData:{},
                servicesData:{}
            };
        },

        mounted() {
            this.loadData()
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {

            dialogueClose() {
                this.$emit("formResponse", this.servicesData);
            },

            loadData(){
                axios.get('Upload/ManualList').then(response=>{
                    this.downloadData = response.data
                })
            },

            async onDownload(download){
                console.log(download)
                await axios({
                    url: 'Upload/DownloadManual',
                    method: 'GET',
                    responseType: 'blob',
                    params:{
                        FileLocation:download.location
                    }
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', download.sector +'.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>