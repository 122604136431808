<template>
    <v-card>
        <v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-dialog v-model="signupDialog" fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  persistent>
            <signup v-if="signupDialog" @formResponse="onResponse" />
        </v-dialog>

        <v-card-text>
            <form>
                <v-form>
                    <v-alert transition="scale-transition"
                             origin="center center"
                             v-show="alert"
                             dense
                             outlined
                             type="error"
                             :timeout="timeout">
                        {{error}}
                    </v-alert>

                    <div class="login-header mb-4">
                        <v-img class="logo-img" src="@/assets/logo.jpg" aspect-ratio="1" contain center></v-img>
                        <div class="text-center">

                            <span class="body-1 font-weight-black">
                                {{ $t("office_header.central") }}
                                {{ $t("office_header.electronic") }}
                                {{ $t("office_header.title") }}
                            </span>

                            <br />
                            <span class="body-1 font-weight-black">
                                CeTMIS
                            </span>
                        </div>
                    </div>
                    <template v-if="isLoginForm">
                        <v-text-field v-model="name"
                                      :error-messages="nameErrors"
                                      :label="$t('user_name')"
                                      required
                                      @input="$v.name.$touch()"
                                      outlined
                                      dense
                                      @blur="$v.name.$touch()"></v-text-field>

                        <v-text-field v-model="password"
                                      :type="'password'"
                                      :error-messages="passwordErrors"
                                      :label="$t('password')"
                                      required
                                      outlined
                                      dense
                                      @input="$v.password.$touch()"
                                      @blur="$v.password.$touch()"></v-text-field>
                        <v-row class="pb-5">
                            <v-col class="col-1"></v-col>
                            <v-col class="col-10">
                                <vue-recaptcha ref="recaptcha"
                                               @verify="onCaptchaVerified"
                                               @expired="onCaptchaExpired"
                                               sitekey="6LdN8ikjAAAAAFyJgmsphCJI8SUfmdSyN09xUFcE" :loadRecaptchaScript="true"></vue-recaptcha>
                            </v-col>
                            <v-col class="col-1"></v-col>
                        </v-row>
                        <div>
                            <v-btn block small color="#265b93" class="white--text" @click="submit">{{$t('login')}}</v-btn>
                            <p @click="changeFormForget" class="forgotPassword text-end pt-2">{{$t('forgot_password')}}</p>

                        </div>

                    </template>
                </v-form>

                <form v-if=" isForgetPasswordForm">
                    <v-alert transition="scale-transition"
                             origin="center center"
                             v-show="alert"
                             dense
                             outlined
                             :type="alertColor==='success'?'success':'error'"
                             :timeout="timeout">
                        {{error}}
                    </v-alert>

                    <v-text-field v-model="email"
                                  :label="$t('email')"
                                  outlined
                                  dense
                                  required></v-text-field>
                    <v-btn block small class="white--text" color="#265b93" @click="submitForget" :loading="requestPasswordLoading" :disabled="requestPasswordLoading">
                        {{$t('submit')}}
                    </v-btn>
                    <p @click="changeFormSignIn" class="pt-1 didYouRemember text-end">{{$t('check_if_remembers')}}</p>
                </form>
                <div class="d-flex justify-space-between">
                    <v-btn text
                           color="#19386c"
                           style="font-size:10px;padding: 0"
                           class="mb-1"
                           dark @click="downloadModalPopup"> <v-icon class="ml-2">mdi-cloud-download</v-icon>Download Manual</v-btn>
                    <v-btn outlined small dark color="#B72626" @click="signup">{{$t('register_new_user')}}</v-btn>

                </div>
                <!--<div class="d-flex justify-center">
      <v-btn color="primary" @click="submit">login</v-btn>
    </div>-->
            </form>
        </v-card-text>
        <v-dialog v-model="dialog.download" persistent max-width="1000px">
            <download v-if="dialog.download" @formResponse="onResponse" />
        </v-dialog>
    </v-card>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import {mapActions, mapGetters} from "vuex";
    import signup from "./Signup";
    import axios from "axios";
    import download from "../modules/dashboard/modal/download";
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        name: "Signin",
        components: { signup, download, VueRecaptcha},
        props: {
            source: String
        },
        watch: {
            alert(newValue) {
                // Close after 5 seconds
                if (newValue) {
                    setTimeout(this.hideAlert, 5000);
                }
            }
        },
        validations: {
            name: {required},
            password: {required}
        },

        data() {
            return {
                formHeader:this.$t('cetmis_login'),
                isLoginForm: true,
                isForgetPasswordForm: false,
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                signupDialog: false,
                alert: false,
                alertColor: 'red',
                timeout: 2000,
                tab: null,
                icons: false,
                centered: false,
                grow: false,
                vertical: false,
                prevIcon: false,
                nextIcon: false,
                right: false,
                error: "",
                tabs: 3,
                name: "",
                password: "",
                errors: "",
                email: "",
                requestPasswordLoading: false,
                dialog: {
                    download: false,
                },
                isVarifyed: false
            };
        },
        computed: {
            ...mapGetters({
                authenticated: "authenticated"
            }),
            nameErrors() {
                const errors = [];
                if (!this.$v.name.$dirty) return errors;
                !this.$v.name.required && errors.push("Name is required.");
                return errors;
            },
            passwordErrors() {
                const errors = [];
                if (!this.$v.password.$dirty) return errors;
                !this.$v.password.required && errors.push("Password is required");
                return errors;
            }
        },
        destroyed() {
        },
        created() {
            localStorage.clear();
            this.initialize();
             localStorage.setItem('lang',this.$i18n.locale);
        },
        methods: {
            onCaptchaVerified (recaptchaToken) {
                if (recaptchaToken) {
                    this.isVarifyed = true
                }
            },
            downloadModalPopup() {
                this.dialog.download = true;
            },
         
            changeFormForget() {
                this.formHeader = this.$t('forgot_password')
                this.isLoginForm = false
                this.isForgetPasswordForm = true
            },
            changeFormSignIn() {
                this.formHeader = this.$t('cetmis_login')
                this.isLoginForm = true
                this.isForgetPasswordForm = false
            },

            onResponse(data) {
                if (data.message) {
                    this.signupDialog = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                } else {
                    this.signupDialog = false;
                }
                this.dialog.download = false;

            },
            dialogueClose() {
                this.$emit('dialogueClose')
            },
            signup() {
                this.signupDialog = true;
            },
            hideAlert() {
                // console.log("hide");
                this.alert = false;
            },
            //redirected to auth->signIn
            ...mapActions({
                signIn: "signIn"
            }),
            initialize() {
                localStorage.clear();
                delete axios.defaults.headers.common['Authorization']
            },
            async submit() {

                if (this.isVarifyed) {
                    this.$v.$touch();
                    let formdata = JSON.stringify({
                        UserName: this.name,
                        password: this.password
                    });
                    //mapaction called

                    let response = await this.signIn(formdata);
                    if (response && response.data != 200) {
                        this.alert = true;
                        this.error = "User Name or Password MisMatch";
                    }
                } else {
                    this.snackbar = true

                    this.callbackResponse.message = "Please verify you are a human.";
                }
               
                this.$refs.recaptcha.reset()
            },

            submitForget() {
                const headers = {'Content-Type': 'application/json'}
                this.requestPasswordLoading = true
                axios.get('User/RequestResetPassword', {
                    params: {
                        email: this.email
                    }
                }, headers).then(response => {
                    console.log(response.data.errors)
                    this.requestPasswordLoading=false
                    if (response.data.success) {
                        this.alert = true
                        this.alertColor = 'success'
                        this.error = "Please Check the email for Code"
                    } else {
                        this.alert = true
                        this.alertColor = 'error'
                        this.error = response.data.errors[0]
                    }

                }).catch(err => {
                    this.alert = true
                    this.alertColor = 'error'
                    this.error = err
                })
            },
            onCaptchaExpired: function () {
                this.$refs.recaptcha.reset();
            }
        }
    };
</script>
<style lang="scss" scoped>
    .v-item-group {
        background: #1565c0;
    }

    .forgotPassword:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #1565c0;
    }

    .didYouRemember:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #1565c0;
    }
    .logo-img {
        height: 6rem;
    }
</style>