<template>
    <v-card>
        <v-toolbar dark style="background-color: #265b93" color="darken-3" class="darken-1">
            <v-toolbar-title>
                {{branchName}} {{ $t("register") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-snackbar v-model="snackbar" color="error" :timeout="callbackResponse.timeout" top>
                {{errorMessage}}
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>

            <v-container>
                <form>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select v-model="formPostData.UserDetails.provinceId"
                                      :items="province"
                                      item-value="id"
                                      item-text="value"
                                      :error-messages="provinceIdErrors"
                                      @input="$v.formPostData.UserDetails.provinceId.$touch()"
                                      outlined
                                      dense
                                      @blur="$v.formPostData.UserDetails.provinceId.$touch()">
                                <template v-slot:label>
                                    {{ $t("select_province") }}
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete v-model="formPostData.UserDetails.divisionId"
                                            :items="division"
                                            item-value="id"
                                            item-text="value"
                                            @change="DivisionChanged"
                                            :error-messages="divisionIdErrors"
                                            @input="$v.formPostData.UserDetails.divisionId.$touch()"
                                            @blur="$v.formPostData.UserDetails.divisionId.$touch()"
                                            outlined 
                                            dense>
                                <template v-slot:label>
                                    {{ $t("select_business_sector") }}
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col outlined>
                            <v-card class="mx-auto pa-4" outlined>
                                <v-app-bar style="background-color: #265b93" color="darken-3" dark>
                                    <v-row justify="center" align="center">
                                        <p class="title">{{branchName}} {{ $t("information") }}</p>
                                    </v-row>
                                </v-app-bar>
                                <v-row class="mt-4">
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchName"
                                                      :label="branchName+ $t('_name')"
                                                      required
                                                      outlined 
                                                      dense
                                                      :error-messages="BranchNameErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchName.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchName.$touch()">

                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchNameNepali"
                                                      :label="branchName+ $t('_name_in_nepali')"
                                                      required
                                                      outlined 
                                                      dense
                                                      :error-messages="BranchNameNepaliErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchNameNepali.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchNameNepali.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-if="isBranchHotel || isBranchHomeStay">

                                        <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="isBranchHotel ?items:homeStays"
                                                  item-value="id"
                                                  item-text="value"
                                                  outlined
                                                  dense
                                                  :label="branchName+ $t('_type')"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-if="isNEPALIBranchHotel || isNEPALIBranchHomeStay">

                                        <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="isNEPALIBranchHotel ?items1:homeStays1"
                                                  item-value="id"
                                                  item-text="value"
                                                  outlined
                                                  dense
                                                  :label="branchName+ $t('_type')"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-if="isAcademic">
                                        <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="['Private','Public']"
                                                  item-value="id"
                                                  item-text="value"
                                                  outlined
                                                  dense
                                                  :label="branchName+ $t('_type')"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-if="isBranchNepaliAviation">
                                        <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="['अन्तर्राष्ट्रिय','आन्तरिक']"
                                                  item-value="id"
                                                  item-text="value"
                                                  outlined
                                                  dense
                                                  :label="branchName+ $t('_type')"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-if="isBranchNepaliAviation">
                                        <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="['International','Domestic']"
                                                  item-value="id"
                                                  item-text="value"
                                                  outlined
                                                  dense
                                                  :label="branchName+ $t('_type')"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-if="isTravelTour">
                                        <v-select v-model="formPostData.BranchInformation.BranchType" item-text="value"
                                                  item-value="id" :items="TravelTourTypeList"
                                                  outlined
                                                  dense
                                                  :label="branchName+ $t('_type')"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-else-if="isBranchAdventure">
                                        <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="adventureType" :label="branchName+ $t('_type')" item-value="value"
                                                  outlined
                                                  dense
                                                  item-text="id"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-else-if="isBranchNepaliAdventure">
                                        <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="adventureType" :label="branchName+ $t('_type')" item-value="value"
                                                  outlined
                                                  dense
                                                  item-text="id"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-select v-model="formPostData.BranchInformation.District"
                                                  :items="districts"
                                                  item-value="id"
                                                  item-text="value"
                                                  required
                                                  outlined
                                                  dense
                                                  :error-messages="BranchADistrictErrors"
                                                  @input="$v.formPostData.BranchInformation.District.$touch()"
                                                  @blur="$v.formPostData.BranchInformation.District.$touch()">
                                            <template v-slot:label>
                                                {{ $t("district") }}
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchAddress"
                                                      :label="branchName+ $t('_address')"
                                                      required
                                                      outlined
                                                      dense
                                                      :error-messages="BranchAddressErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchAddress.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchAddress.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchAddressNepali"
                                                      :label="branchName+ $t('_address_in_nepali')"
                                                      required
                                                      outlined
                                                      dense
                                                      :error-messages="BranchAddressNepaliErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchAddressNepali.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchAddressNepali.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <!--        <v-text-field
                                          v-model="formPostData.BranchInformation.EstablishedYear"
                                          :label="branchName+ ' Estd Year'"
                                          required
                                          :error-messages="EstablishedYearErrors"
                                          @input="$v.formPostData.BranchInformation.EstablishedYear.$touch()"
                                          @blur="$v.formPostData.BranchInformation.EstablishedYear.$touch()"
                                        ></v-text-field>-->
                                        <v-menu v-model="dateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="formPostData.BranchInformation.EstablishedYear"
                                                              clearable
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"
                                                              required
                                                              outlined
                                                              dense
                                                              :error-messages="EstablishedYearErrors"
                                                              @input="$v.formPostData.BranchInformation.EstablishedYear.$touch()"
                                                              @blur="$v.formPostData.BranchInformation.EstablishedYear.$touch()">
                                                    <template v-slot:label>
                                                        {{ $t("establishment_year") }}
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="formPostData.BranchInformation.EstablishedYear">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1" v-if="isBranchNepaliAviation">
                                        <v-text-field outlined
                                                      dense v-model="formPostData.BranchInformation.NumberOfFleet"
                                                      :label="'फ्लीट को संख्या'">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="my-1 py-1 ">
                                        <v-text-field outlined
                                                      dense v-model="formPostData.BranchInformation.BranchLatitude"
                                                      :label="branchName+ $t('_latitude')"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="my-1 py-1 ">
                                        <v-text-field outlined
                                                      dense v-model="formPostData.BranchInformation.BranchLongitude"
                                                      :label="branchName+ $t('_longitude')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field outlined
                                                      dense v-model="formPostData.BranchInformation.BranchEmail"
                                                      :label="branchName+ $t('_email')"
                                                      required
                                                      :error-messages="BranchEmailErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchEmail.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchEmail.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field outlined
                                                      dense v-model="formPostData.BranchInformation.BranchWebsite"
                                                      :label="branchName+ $t('_website')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field outlined
                                                      dense v-model="formPostData.BranchInformation.BranchPhoneNumber"
                                                      :label="branchName+ $t('_phone_number')"
                                                      required
                                                      :error-messages="BranchPhoneNumberErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchPhoneNumber.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchPhoneNumber.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-file-input outlined
                                                      dense
                                                      v-model="formPostData.BranchInformation.Logo"
                                                      show-size
                                                      prepend-icon=""
                                                      prepend-inner-icon="mdi-camera"
                                                      :error-messages="LogoErrors"
                                                      @input="$v.formPostData.BranchInformation.Logo.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.Logo.$touch()">
                                            <template v-slot:label>
                                                {{ $t("logo") }}
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-file-input outlined
                                                      dense v-model="formPostData.BranchInformation.RegisterDocument"
                                                      show-size
                                                      prepend-inner-icon="mdi-camera"
                                                      prepend-icon=""
                                                      :error-messages="RegisterDocumentErrors"
                                                      @input="$v.formPostData.BranchInformation.RegisterDocument.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.RegisterDocument.$touch()">
                                            <template v-slot:label>
                                                {{ $t("register_document") }}
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-file-input outlined
                                                      dense v-model="formPostData.BranchInformation.TaxClearanceDocument"
                                                      show-size
                                                      prepend-inner-icon="mdi-camera"
                                                      prepend-icon=""
                                                      :error-messages="TaxClearanceDocumentErrors"
                                                      @input="$v.formPostData.BranchInformation.TaxClearanceDocument.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.TaxClearanceDocument.$touch()">
                                            <template v-slot:label>
                                                {{ $t("tax_clearance_document") }}
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-file-input v-model="formPostData.BranchInformation.VatDocument"
                                                      show-size
                                                      outlined
                                                      dense
                                                      prepend-icon=""
                                                      prepend-inner-icon="mdi-camera"
                                                      :error-messages="VatDocumentErrors"
                                                      @input="$v.formPostData.BranchInformation.VatDocument.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.VatDocument.$touch()">
                                            <template v-slot:label>
                                                {{ $t("vat_document") }}
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>




                        <v-col>
                            <v-card class="mx-auto pa-4" outlined>
                                <v-app-bar style="background-color: #265b93" color="darken-3" dark>
                                    <v-row justify="center" align="center">
                                        <p class="title" justify="center">{{ $t("user_info") }}</p>
                                    </v-row>
                                </v-app-bar>

                                <v-row class="mt-4">
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.firstName"
                                                      required
                                                      outlined
                                                      dense
                                                      :error-messages="firstNameErrors"
                                                      @input="$v.formPostData.UserDetails.firstName.$touch()"
                                                      @blur="$v.formPostData.UserDetails.firstName.$touch()">
                                            <template v-slot:label>
                                                {{ $t("first_name") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.lastName"
                                                      label="Last Name"
                                                      outlined
                                                      dense
                                                      :error-messages="lastNameErrors"
                                                      @input="$v.formPostData.UserDetails.lastName.$touch()"
                                                      @blur="$v.formPostData.UserDetails.lastName.$touch()">
                                            <template v-slot:label>
                                                {{ $t("last_name") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.address"
                                                      label="Address"
                                                      outlined
                                                      dense
                                                      :error-messages="addressErrors"
                                                      @input="$v.formPostData.UserDetails.address.$touch()"
                                                      @blur="$v.formPostData.UserDetails.address.$touch()">
                                            <template v-slot:label>
                                                {{ $t("address") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.email"
                                                      label="E-mail"
                                                      outlined
                                                      dense
                                                      :error-messages="emailErrors"
                                                      @input="$v.formPostData.UserDetails.email.$touch()"
                                                      @blur="$v.formPostData.UserDetails.email.$touch()">
                                            <template v-slot:label>
                                                {{ $t("email") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.userName"
                                                      label="User Name"
                                                      outlined
                                                      dense
                                                      :error-messages="userNameErrors"
                                                      @input="$v.formPostData.UserDetails.userName.$touch()"
                                                      @blur="$v.formPostData.UserDetails.userName.$touch()">
                                            <template v-slot:label>
                                                {{ $t("user_name") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" md=6 class="my-1 py-1">
                                      <v-file-input
                                        v-model="formPostData.BranchInformation.CitizenDocument"
                                        show-size
                                        label="Citizen Copy"
                                      ></v-file-input>
                                    </v-col>-->
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.password"
                                                      label="Password"
                                                      required
                                                      outlined
                                                      dense
                                                      type="password"
                                                      :error-messages="passwordErrors"
                                                      @input="$v.formPostData.UserDetails.password.$touch()"
                                                      @blur="$v.formPostData.UserDetails.password.$touch()">
                                            <template v-slot:label>
                                                {{ $t("password") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.confirmPassword"
                                                      label="Confirm Pasword"
                                                      type="password"
                                                      outlined
                                                      dense
                                                      :error-messages="confirmPasswordErrors"
                                                      @input="$v.formPostData.UserDetails.confirmPassword.$touch()"
                                                      @blur="$v.formPostData.UserDetails.confirmPassword.$touch()">
                                            <template v-slot:label>
                                                {{ $t("confirm_password") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Back</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>


<script>
    import { email, required, sameAs } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "SignUp",
        props: {
            source: String
        },
        watch: {
            close: {
                handler: function (val) {
                    let data = {};
                    data.val = val;
                    data.type = "close";
                    this.$emit("formResponse", val, this.responseData);
                    this.close = false;
                },
                deep: true
            },
            "formPostData.UserDetails.provinceId": {
                handler: async function (val, oldVal) {
                    if (val != oldVal) {
                        this.error = true;
                        this.division = [];
                        this.branch = [];
                        let disNepali = localStorage.getItem('lang');
                        if (disNepali == 'ne') {


                            const divisionBranch = await axios.get("Division/DDLDivisionListNewNE/", {
                                params: { ProvinceID: val }
                            });
                            this.division = divisionBranch.data
                        }
                        else {
                            const divisionBranch = await axios.get("Division/DDLDivisionListNew/", {
                                params: { ProvinceID: val }

                            });
                            this.division = divisionBranch.data
                        }

                        let disNep = localStorage.getItem('lang');
                        if (disNep == 'en') {
                            const { data } = await axios.get("District/GetDistrictListByProvince/" + val);
                            this.districts = data
                        }
                        if (disNep == 'ne') {
                            const { data } = await axios.get("District/GetDistrictListByProvinceNE/" + val);
                            this.districts = data
                        }
                        this.formPostData.UserDetails.provinceId = val;
                    }
                },
                deep: true
            }
        },
        computed: {
            isTravelTour() {
                if (this.branchName === 'TravelTour' || this.branchName === 'यात्रा भ्रमण') {
                    this.branchName === 'TravelTour'
                    return true;
                }
                return false

            },
            isAcademic() {
                if (this.branchName === 'Academic') {
                    this.branchName === 'Academic'
                    return true;
                }
                return false

            },
            isNepaliAcademic() {
                if (this.branchName === 'शैक्षिक') {
                    this.branchName === 'Academic'
                    return true;
                }
                return false

            },
            isBranchAviation() {
                if (this.branchName === 'Aviation' || this.branchName === 'Helicopter') {
                    this.branchName === 'Aviation' || this.branchName === 'Helicopter'
                    return true;
                }
                return false

            },

            isBranchNepaliAviation() {
                if (this.branchName === 'उड्डयन' || this.branchName === 'हेलिकप्टर') {
                    this.branchName === 'Aviation' || this.branchName === 'Helicopter'
                    return true;
                }
                return false

            },
            isBranchHotel() {
                if (this.branchName == 'Hotel') {
                    this.branchName === 'Hotel'
                    return true;
                }
                return false

            },
            isNEPALIBranchHotel() {
                if (this.branchName == 'होटल') {
                    this.branchName === 'Hotel'
                    return true;
                }
                return false

            },
            isBranchHomeStay() {

                if (this.branchName == 'HomeStay') {
                    this.branchName === 'HomeStay'
                    return true;
                }
                return false

            },
            isNEPALIBranchHomeStay() {
                if (this.branchName == 'होमस्टे') {
                    this.branchName === 'HomeStay'
                    return true;
                }
                return false

            },
            isBranchNepaliAdventure() {

                if (this.branchName === 'साहसिक') {
                    this.branchName === 'Adventure'
                    return true;
                }
                return false

            },
            isBranchAdventure() {
                if (this.branchName === 'Adventure') {
                    this.branchName === 'Adventure'
                    return true;
                }
                return false

            },
            provinceIdErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.provinceId.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.provinceId.required &&
                        errors.push(`${this.$t("province_is_required")}`);
                }
                return errors;
            },
            divisionIdErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.divisionId.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.divisionId.required &&
                        errors.push(`${this.$t("business_sector_is_required")}`);
                }
                return errors;
            },
            firstNameErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.firstName.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.firstName.required &&
                        errors.push(`${this.$t("first_name_is_required")}`);
                }
                return errors;
            },
            userNameErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.userName.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.userName.required &&
                        errors.push(`${this.$t("user_name_is_required")}`);
                }
                return errors;
            },
            lastNameErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.lastName.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.lastName.required &&
                        errors.push(`${this.$t("last_name_is_required")}`);
                }
                return errors;
            },
            addressErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.address.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.address.required &&
                        errors.push(`${this.$t("address_is_required")}`);
                }
                return errors;
            },
            emailErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.email.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.email.email &&
                        errors.push(`${this.$t("must_be_valid_email")}`);
                    !this.$v.formPostData.UserDetails.email.required &&
                        errors.push(`${this.$t("email_is_required")}`);
                }
                return errors;
            },
            passwordErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.password.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.password.required &&
                        errors.push(`${this.$t("password_is_required")}`);
                }
                return errors;
            },
            confirmPasswordErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.confirmPassword.$dirty)
                        return errors;
                    !this.$v.formPostData.UserDetails.confirmPassword.required &&
                        errors.push(`${this.$t("confirm_password_is_required")}`);
                    !this.$v.formPostData.UserDetails.confirmPassword.sameAsPassword &&
                        errors.push(`${this.$t("password_must_be_identical")}`);
                }
                return errors;
            },
            BranchPhoneNumberErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchPhoneNumber.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchPhoneNumber.required &&
                        errors.push(`${this.$t("phone_number_is_required")}`);
                }
                return errors;
            },
            BranchAddressErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchAddress.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchAddress.required &&
                        errors.push(`${this.$t("address_is_required")}`);
                }
                return errors;
            },
            BranchAddressNepaliErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchAddressNepali.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchAddressNepali.required &&
                        errors.push(`${this.$t("address_is_required")}`);
                }
                return errors;
            },
            BranchADistrictErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.District.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.District.required &&
                        errors.push(`${this.$t("district_is_required")}`);
                }
                return errors;
            },
            EstablishedYearErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.EstablishedYear.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.EstablishedYear.required &&
                        errors.push(`${this.$t("establishment_year_is_required")}`);
                }
                return errors;
            },
            BranchEmailErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchEmail.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchEmail.email &&
                        errors.push(`${this.$t("must_be_valid_email")}`);
                    !this.$v.formPostData.BranchInformation.BranchEmail.required &&
                        errors.push(`${this.$t("email_is_required")}`);
                }
                return errors;
            },
            BranchNameErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchName.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchName.required &&
                        errors.push(`${this.$t("name_is_required")}`);
                }
                return errors;
            },
            BranchNameNepaliErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchNameNepali.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchNameNepali.required &&
                        errors.push(`${this.$t("name_is_required")}`);
                }
                return errors;
            },
            LogoErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.Logo.$dirty) return errors;
                    !this.$v.formPostData.BranchInformation.Logo.required &&
                        errors.push(`${this.$t("document_is_required")}`);
                }
                return errors;
            },
            RegisterDocumentErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.RegisterDocument.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.RegisterDocument.required &&
                        errors.push(`${this.$t("document_is_required")}`);
                }
                return errors;
            },
            TaxClearanceDocumentErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.TaxClearanceDocument.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.TaxClearanceDocument.required &&
                        errors.push(`${this.$t("document_is_required")}`);
                }
                return errors;
            },
            VatDocumentErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.VatDocument.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.VatDocument.required &&
                        errors.push(`${this.$t("document_is_required")}`);
                }
                return errors;
            }
        },
        validations: {
            formPostData: {
                UserDetails: {
                    provinceId: { required },
                    divisionId: { required },
                    firstName: { required },
                    lastName: { required },
                    address: { required },
                    email: { required, email },
                    password: { required },
                    userName: { required },
                    confirmPassword: {
                        required,
                        sameAsPassword: sameAs("password")
                    }
                },
                BranchInformation: {
                    BranchPhoneNumber: { required },
                    District: { required },
                    Logo: { required },
                    BranchName: { required },
                    BranchNameNepali: { required },
                    RegisterDocument: { required },
                    TaxClearanceDocument: { required },
                    VatDocument: { required },
                    BranchAddress: { required },
                    BranchAddressNepali: { required },
                    EstablishedYear: { required },
                    BranchEmail: { required, email }
                },
            },
        },
        data() {
            return {
                responseData: {},
                districts: [],
                callbackResponse: {
                    timeout: 6000
                },
                errorMessage: "",
                snackbar: false,
                close: false,
                error: false,
                created: false,
                show1: false,
                show2: false,
                provShow: false,
                divShow: false,
                items: [
                    { id: 'OneStar', value: 'One Star Hotel' },
                    { id: 'TwoStar', value: 'Two Star Hotel' },
                    { id: 'ThreeStar', value: 'Three Star Hotel' },
                    { id: 'FourStar', value: 'Four Star Hotel' },
                    { id: 'FiveStar', value: 'Five Star Hotel' },
                    { id: 'FiveStarDeluxe', value: 'Five Star Deluxe Hotel' },
                    { id: 'HeritageBoutique', value: 'Heritage Boutique Hotel' },
                    { id: 'NormalResort', value: 'Normal Resort' },
                    { id: 'DeluxeResort', value: 'Deluxe Resort' },
                    { id: 'LuxuryResort', value: 'Luxury Resort' },
                ], items1: [
                    { id: 'OneStar', value: 'एक तारे होटल' },
                    { id: 'TwoStar', value: 'दुई तारे होटल' },
                    { id: 'ThreeStar', value: 'तीन तारे होटल' },
                    { id: 'FourStar', value: 'चार तारे होटल' },
                    { id: 'FiveStar', value: 'पाँच तारे होटल' },
                    { id: 'FiveStarDeluxe', value: 'पाँच तारे डिलक्स होटल' },
                    { id: 'HeritageBoutique', value: 'हेरिटेज बुटिक होटल' },
                    { id: 'NormalResort', value: 'सामान्य रिसोर्ट' },
                    { id: 'DeluxeResort', value: 'डिलक्स रिसोर्ट' },
                    { id: 'LuxuryResort', value: 'लक्जरी रिसोर्ट' },
                ],
                homeStays: [
                    { id: 'Private', value: 'Private' },
                    { id: 'Community', value: 'Community' },
                ],
                homeStays1: [
                    { id: 'Private', value: 'निजी' },
                    { id: 'Community', value: 'समुदाय' },
                ],

                adventureType: [],
                province: [],
                division: [],
                branch: [],
                branchName: "Brand",
                dateMenu: false,
                TravelTourTypeList: [],
                formPostData: {
                    BranchInformation: {},
                    UserDetails: {}
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        created() {
            this.formData();
            if (localStorage.getItem('lang') === 'ne') {
                this.branchName = 'ब्रान्ड'
            }
            else {
                this.branchName = "Brand"
            }
            this.error = true;
        },
        destroyed() {
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            branchMethod(index) {
                console.log(this.division[index]);
            },
            async formData() {


                let advNepali = localStorage.getItem('lang');
                if (advNepali === 'ne') {
                    const adventure = await axios.get("Shared/AdventuresListNE");
                    this.adventureType = adventure.data;
                }
                else {
                    const adventure = await axios.get("Shared/AdventuresList");
                    this.adventureType = adventure.data;

                }



                let provNepali = localStorage.getItem('lang');
                if (provNepali === 'ne') {
                    const provinceRequest = await axios.get("Province/DDLProvinceListNE");
                    this.province = provinceRequest.data;

                }
                else {
                    const provinceRequest = await axios.get("Province/DDLProvinceList");
                    this.province = provinceRequest.data;
                }
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    this.formPostData.isAnonymous = true;
                    var form = new FormData();

                    form.append(
                        "BranchInformation.Logo",
                        this.formPostData.BranchInformation.Logo
                    );
                    form.append(
                        "BranchInformation.TaxClearanceDocument",
                        this.formPostData.BranchInformation.TaxClearanceDocument
                    );
                    form.append(
                        "BranchInformation.BranchType",
                        this.formPostData.BranchInformation.BranchType
                    );
                    form.append(
                        "BranchInformation.VatDocument",
                        this.formPostData.BranchInformation.VatDocument
                    );
                    form.append(
                        "BranchInformation.RegisterDocument",
                        this.formPostData.BranchInformation.RegisterDocument
                    );
                    form.append(
                        "BranchInformation.BranchName",
                        this.formPostData.BranchInformation.BranchName
                    );
                    form.append(
                        "BranchInformation.BranchNameNepali",
                        this.formPostData.BranchInformation.BranchNameNepali
                    );
                    form.append(
                        "BranchInformation.BranchAddress",
                        this.formPostData.BranchInformation.BranchAddress
                    );
                    form.append(
                        "BranchInformation.BranchAddressNepali",
                        this.formPostData.BranchInformation.BranchAddressNepali
                    );
                    form.append(
                        "BranchInformation.District",
                        this.formPostData.BranchInformation.District
                    );
                    form.append(
                        "BranchInformation.NumberOfFleet",
                        this.formPostData.BranchInformation.NumberOfFleet ? this.formPostData.BranchInformation.NumberOfFleet : ""
                    );
                    form.append(
                        "BranchInformation.EstablishedYear",
                        this.formPostData.BranchInformation.EstablishedYear
                    );
                    form.append(
                        "BranchInformation.BranchPhoneNumber",
                        this.formPostData.BranchInformation.BranchPhoneNumber
                    );
                    form.append(
                        "BranchInformation.BranchWebsite",
                        this.formPostData.BranchInformation.BranchWebsite
                    );
                    form.append(
                        "BranchInformation.Latitude",
                        this.formPostData.BranchInformation.BranchLatitude
                    );
                    form.append(
                        "BranchInformation.Longitude",
                        this.formPostData.BranchInformation.BranchLongitude
                    );
                    form.append(
                        "BranchInformation.BranchEmail",
                        this.formPostData.BranchInformation.BranchEmail
                    );
                    form.append(
                        "BranchInformation.BranchCode",
                        this.formPostData.BranchInformation.BranchCode
                    );
                    form.append(
                        "UserDetails.FirstName",
                        this.formPostData.UserDetails.firstName
                    );
                    form.append("UserDetails.MinistryID", 1);
                    form.append(
                        "UserDetails.LastName",
                        this.formPostData.UserDetails.lastName
                    );
                    form.append(
                        "UserDetails.Address",
                        this.formPostData.UserDetails.address
                    );
                    form.append("UserDetails.Email", this.formPostData.UserDetails.email);
                    form.append(
                        "UserDetails.UserName",
                        this.formPostData.UserDetails.userName
                    );
                    form.append(
                        "UserDetails.Password",
                        this.formPostData.UserDetails.password
                    );
                    form.append(
                        "UserDetails.DivisionID",
                        this.formPostData.UserDetails.divisionId
                    );
                    form.append(
                        "UserDetails.ProvinceID",
                        this.formPostData.UserDetails.provinceId
                    );
                    form.append("isAnonymous", "true");
                    this.snackbar = false;
                    axios
                        .post("User/CreateUser", form)
                        .then(response => {
                            this.responseData = response.data;
                            if (response.data.success) {
                                this.editedData = {};
                                this.servicesData.data = response.data
                                this.servicesData.message = "User Register Succcessfully Wait For Confirmation"
                                this.servicesData.color = "success"
                                this.dialogueClose()
                            } else {
                                console.log(response.data.errors[0]);
                                this.errorMessage = response.data.errors[0];
                                this.snackbar = true;
                            }
                        })
                        .catch(data => {
                            console.log(data);
                            this.errorMessage = "Server Error, Please Contact Admin";
                            this.snackbar = true;
                        });
                }
                this.submitStatus = "PENDING";
                setTimeout(() => {
                    this.submitStatus = "OK";
                }, 500);
            },
            async DivisionChanged(index) {
                console.log(index);
                let div = this.division.filter(item => item.id === index);
                this.branchName = div[0].value;
                if (this.branchName == "TravelTour" || this.branchName == "यात्रा भ्रमण") {
                    if (this.branchName == "TravelTour") {
                        this.branchName === "TravelTour"
                        let type = await axios.get("Shared/GetTravelTourTypeList");
                        this.TravelTourTypeList = type.data;
                    }
                    if (this.branchName == "यात्रा भ्रमण") {
                        this.branchName === "TravelTour"
                        let type = await axios.get("Shared/GetTravelTourTypeNEList");
                        this.TravelTourTypeList = type.data;
                    }
                }
            }
        }
    };
</script>
<style lang="scss" scoped>
    .v-item-group {
        background: #1565c0;
    }

    input {
        display: none;
    }

    .v-card--outlined {
        height: 100%;
    }

    ::v-deep .v-select__slot, ::v-deep .v-input__slot {
        min-height: 35px !important;
    }

    ::v-deep .v-list-item {
        &:hover {
            background-color: #80DEEA !important;
        }
    }
</style>